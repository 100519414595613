import { defineComponent, type FunctionalComponent, onMounted, type PropType, type VNode } from "vue";
export { defineComponent, type PropType } from "vue";
export type GaVueComponent = VNode;

export function createParentComponent<Props = Record<string, never>>(
  component: (props: Props & { children?: GaVueComponent | GaVueComponent[] }) => GaVueComponent,
): FunctionalComponent<Props, Record<string, never>, { default?: void }> /*(props: Props, ctx: GaVueContext) => GaVueComponent*/ {
  return (props, ctx) => component({ ...props, children: ctx.slots.default?.() });
}

export const GaLowLevelEffect = defineComponent({
  props: {
    sideEffect: {
      type: Object as PropType<() => Promise<void>>,
      required: true,
    },
    content: {
      type: Object as PropType<GaVueComponent>,
      required: true,
    },
  },
  setup: (props) => {
    onMounted(async () => {
      await props.sideEffect();
    });
    return () => <>{props.content}</>;
  },
});
